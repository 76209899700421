/*
 * footer.less
 * -----------------------------------------------
*/
.footer {
	 a {
		color: @gray-base;
		&:focus,
		&:hover,
		&:active {
			color: @gray-dimgray;
		}
	}

	.widget {
		.widget-title {
			margin-top: 0px;
		}
	}

	.widget.dark {
		.quick-contact-form {
			button {
				border-color: @black-333;
				color: lighten(@black, 56.30%);

				&:hover {
					background-color: @gray-lighter;
					border-color: @gray-lighter;
					color: @black-333;
				}
			}
		}
	}
}

