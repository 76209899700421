/*
 * blog.less
 * -----------------------------------------------
*/
.blog-posts {

	.post {
		position: relative;
		.entry-title {
			text-transform: uppercase;
		}

		.post-thumb {
			overflow: hidden;
			position: relative;
		}

		.entry-content {
			position: relative;
			.post-date,.post-visited {
				color: @gray-darkgray;
				font-size: 13px;
			}

			.post-date {
			  &.right {
				top: -10px;
				height: 60px;
				padding: 6px 8px;
				position: absolute;
				right: 0;
				text-align: center;
				width: 60px;
				}
			}

			.post-date {
				background: @black-222 none repeat scroll 0 0;
	  			display: inline-block;
	  			padding: 0px 14px;
	  			top: 0;
			}

			ul li {
				margin-bottom: 0;
				margin-top: 0;
			}
		}

		.entry-meta {

	  		span i {
				margin-right: 5px;
	  		}

	  		span {
				font-size: 12px;
				margin-right: 15px;
	  		}
		}

		.post-date {

			p,a {
			  	font-size: 14px;
			  	color: @gray-darkgray;
			}

			a:hover {
			   	color: @black-333;
			}
		}

		.like-comment li {
			margin-bottom: 0;
			margin-top: 0;
		}

		.post-thumb {
			.owl-dots {
				bottom: 0;
				position: absolute;
			    right: 10px;

			     .owl-dot span {
			     	border-radius: 50%;
			     	height: 10px;
			     	width: 10px;
			     }
			}
		}
	}
}

.like-comment {
	li {

		i {
		  font-size: 20px;
		  margin-right: 3px;
		  vertical-align: middle;
		}
	}
	&.small-font {
		li {
			i {
				font-size: 16px;
			}
		}
	}
}

.blog-pull-right {
  float: right;
}

.media-post {
	.post-thumb {
		 float: left;
  		 margin: 0 10px 10px 0;
	}

	.post-right {
  		overflow: hidden;
	}

	.post-title {

		a {			
		  font-size: 13px;
		  letter-spacing: 1px;
		}
	}
}

@media only screen and (max-width : 991px) {
.blog-posts .post {
  	margin-bottom: 30px;
	}
}

@media only screen and (max-width : 767px) {
.blog-pull-right {
  	float: none;
	}
}

/* -------- Entry-Meta Absolute ---------- */
.post .entry-header {
  position: relative;
  overflow: hidden;
}
.post .entry-meta.meta-absolute {
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}
.post .entry-meta.meta-absolute i {
	display: block;
	font-size: 28px;
}

.post .entry-header .entry-date {
  position: absolute;
  top: 0;
}
.post-overlay::after {
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  content: "";
  height: 100%;
  opacity: 0;
  position: absolute;
  top: -100%;
  transition: all 300ms ease-in-out 0s;
  width: 100%;
}
.post:hover .post-overlay::after {
	opacity: 1;
	top: 0;
}
.post .entry-header .post-link {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  top: 0;
  transition: all 300ms ease-in-out 0s;
  opacity: 0;
}
.post:hover .entry-header .post-link {
	opacity: 1;
	top: 45%;
}
.post .entry-header .post-link a {
  border: 1px solid @white-base;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  width: 32px;
}
.post .entry-header .post-link i {
  color: @white-base;
  font-size: 14px;
}
.post .entry-header .post-link a:hover i {
	color: @white-base;
}
/* -------- Blog Single ---------- */
.single-post {

	article {
		border-bottom: 1px solid @gray-lightgray;
		margin-bottom: 20px;
		padding-bottom: 30px;

		.entry-title {
			font-family: "Open Sans", sans-serif;
			padding: 15px 0 0;
			text-transform: capitalize;
		}
	}

	.entry-content {
		border: none;
	}

	.author-details {
		border-top: 1px solid @gray-lightgray;
		border-bottom: 1px solid @gray-lightgray;
		margin: 20px 0;
		padding: 20px 0;
	}

	.post-share {
		ul {
			margin-bottom: 15px;

			li {
				display: inline-block;
				margin-right: 5px;

				a {
					background: none repeat scroll 0 0 @gray-lighter;
					border: 1px solid @gray-lighter;
					border-radius: 0;
					
					i {
						color: @black-555;
					}

					&:hover {

						i {
							color: @black-111;
						}
					}
				}
			}
		}
	}

	.media-heading {
		margin-bottom: 5px;
		margin-top: 0;
	}

	.comments-title {
		margin-bottom: 15px;
	}

	.comment-list {
		padding-left: 0;

		h6 {
			font-family: helvetica;
		}

		li {
			list-style: none;
		}

		.comment-author {
			margin-top: 30px;
		}

		.comment-date,
		.comment-heading {
			font-size: 13px;
		}
	}

	.reply-box {
		
		h4 {
			margin-bottom: 15px;
		}

		.form-group {
			input {
				border-radius: 0;
				height: 45px;
				margin-bottom: 28px;
			}

			textarea, {
				border-radius: 0;
			}
		}

		#comment-form {
			button {
				&.btn {
					margin-right: 0;
				}
			}
		}

		.btn-primary {
			margin-right: 0;
		}

		.tagline {
			border-top: 1px solid @gray-lightgray;
		}
	}
}

.reply-box .form-group input {
	border-radius: 0;
	height: 45px;
	margin-bottom: 30px;
}