@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        legalfirm
Version:        1.0
Last change:    04.25.2016
Primary use:    Health & Medical HTML Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-legalfirm/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
@import "../css/stroke-gap-icons.css";
@import "../css/flaticon-set-law.css";
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800);
@import url(http://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,200,500,700,600,800);
@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,400italic,700,700italic);

// Initialize Variables
@import "less-legalfirm/variables.less";
@import "less-legalfirm/mixins.less";

// Typography
@import "less-legalfirm/typography.less";

// Common Styles
@import "less-legalfirm/common.less";
@import "less-legalfirm/extra.less";
@import "less-legalfirm/overlay.less";

// Header
@import "less-legalfirm/header.less";

// Nav
@import "less-legalfirm/nav.less";

// Content Blocks
@import "less-legalfirm/topbar.less";
@import "less-legalfirm/inner-header-title.less";
@import "less-legalfirm/vertical-nav.less";
@import "less-legalfirm/menu-full-page.less";
@import "less-legalfirm/boxed-layout.less";
@import "less-legalfirm/form.less";
@import "less-legalfirm/side-push-panel.less";
@import "less-legalfirm/box-hover-effect.less";
@import "less-legalfirm/gallery-isotope.less";
@import "less-legalfirm/sliders.less";
@import "less-legalfirm/home.less";
@import "less-legalfirm/about.less";
@import "less-legalfirm/causes.less";
@import "less-legalfirm/volunteer.less";
@import "less-legalfirm/contact.less";
@import "less-legalfirm/event.less";
@import "less-legalfirm/practice-area.less";
@import "less-legalfirm/services.less";
@import "less-legalfirm/job.less";
@import "less-legalfirm/shop.less";
@import "less-legalfirm/blog.less";

// Shortcodes
@import "less-legalfirm/shortcodes.less";


// Widgets
@import "less-legalfirm/widgets.less";


// Footer
@import "less-legalfirm/footer.less";