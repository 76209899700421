/*
 * about.less
 * -----------------------------------------------
*/

/* -------- Bg Img Box ---------- */
.bg-img-box {
	position: relative;

	.caption {
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 20px;
		position: absolute;
		top: 0;

		.title {
			font-size: 30px;
			line-height: 39px;
		}

		p {
			max-width: 280px;
		}
	}
}

.gallery_sec .gallery_img {
    float: left;
    padding-top: 3px;
}
.gallery_sec .gallery_img:nth-child(2), .gallery_sec .gallery_img:nth-child(5), .gallery_sec .gallery_img:nth-child(4) {
    padding-left: 3px;
}
.transform_img_holder {
    overflow: hidden;
    position: relative;
}


.gallery_sec .gallery_img .overlay {
    background: rgba(26, 139, 179, 0.9) none repeat scroll 0 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transition: all 0.25s ease-in-out 0s;
    width: 100%;
}

.overlay {
    background: rgba(23, 130, 199, 0.9) none repeat scroll 0 0;
}

.video-popup {
	position: relative;
}
.video-popup a i {
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 44%;
}
.attorney-address li {
    background-color: @white-f7;
	margin-bottom: 30px;
	padding: 15px 20px;
}