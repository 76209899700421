/*
 * Shortcode: dividers.less
 * -----------------------------------------------
*/
.divider {
	background-repeat: repeat;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	position: relative;
	overflow: hidden;
	width: 100%;
}

/* -------- Parallax ---------- */
.parallax {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background-size: cover;
	background-attachment: fixed;
	background-repeat: repeat-y;
	background-position: top center;
}
.mobile-parallax {
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
}
.divider {
	.container {
		padding-top: 70px;
		padding-bottom: 70px;

		&.small-padding {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		&.extra-padding {
			padding-top: 180px;
			padding-bottom: 180px;
		}


	}
}
.stay_with_finance {
  position:  relative; 
}
.stay_with_finance img {
  float: right;
  margin-right: -63px;
  max-width: none;
}
.stay_with_finance .text_container {
  left: 13px;
  position: absolute;
  top: 136px;
}